import axios from 'axios';

export async function getHistoryByAddress(address: string, limit?: number, next?: string): Promise<any> {
  try {
    let url = `${process.env.REACT_APP_HISTORY_API_URL}/${address.toLowerCase()}`;
    const params: { limit?: number; next?: string } = {};
    if (limit) params.limit = limit;
    if (next) params.next = next;
    // console.log('Fetching data from API:', url, params);

    const response = await axios.get(url, {
      headers: {
        'x-api-key': process.env.REACT_APP_HISTORY_API_KEY || 'unknown',
      },
      params
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
}
