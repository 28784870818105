
import { useState, ChangeEvent, FormEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import { ethers } from "ethers";
import ENECTION_LOGO from "../assets/images/enection-logo.svg";

function Header() {
    const [searchInput, setSearchInput] = useState<string>("");
    const history = useHistory();

    async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const { location } = history;

        console.log("Search Input:", searchInput); // Debugging line

        if (searchInput.startsWith("0x") && searchInput.length === 66) {
            // Transaction hash
            const txExists = await checkTransactionExists(searchInput);
            if (txExists) {
                if (location.pathname !== `/tx/${searchInput}`) {
                    history.push(`/tx/${searchInput}`);
                }
            } else {
                // If transaction does not exist, assume it's a block hash
                if (location.pathname !== `/block/${searchInput}`) {
                    history.push(`/block/${searchInput}`);
                }
            }
        } else if (searchInput.startsWith("0x") && searchInput.length === 42) {
            // Address
            if (location.pathname !== `/address/${searchInput}`) {
                history.push(`/address/${searchInput}`);
            }
        } else if (!isNaN(Number(searchInput)) && Number.isInteger(Number(searchInput))) {
            // Block number
            if (location.pathname !== `/block/${searchInput}`) {
                history.push(`/block/${searchInput}`);
            }
        } else {
            alert("Please enter a valid transaction hash, block number, or block hash.");
        }

        setSearchInput("");  // Clear the input field after submission
    }

    async function checkTransactionExists(txHash: string) {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JSON_RPC_URL);
        const tx = await provider.getTransaction(txHash);
        return tx !== null;
    }

    return (
        <header className="flex px-24 py-6 bg-white items-center border-b">
            <Link to="/">
                <img src={ENECTION_LOGO} className="h-10" alt="Enection Logo"></img>
            </Link>
            <h1 className="ml-4 text-[#21325B] font-bold text-2xl mr-auto">
                <Link to="/">Enection Power Tracking Explorer</Link>
            </h1>
            <form className="w-5/12 ml-4" onSubmit={handleOnSubmit}>
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only">
                    Search Transaction Hash, Block Number, or Block Hash
                </label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="transaction-search"
                        className="block text-gray-900 w-full p-4 pl-10 text-sm border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search transaction hashes, block numbers, or block hashes..."
                        required
                        value={searchInput}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSearchInput(event.target.value);
                        }}
                    />
                    <button
                        type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                    >
                        Search
                    </button>
                </div>
            </form>
        </header>
    );
}

export default Header;