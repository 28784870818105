import React from "react";
import { Log } from "@ethersproject/providers"; // Assuming you are using ethers.js for Log type
import { ethers } from "ethers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { TokenInfo } from "./Transaction";
import { Link } from "react-router-dom";
import { normalizeAddress } from "../utils/common";

interface LogsProps {
    logs: Log[];
    decimals: number
}

const Logs: React.FC<LogsProps> = ({ logs, decimals }) => {
    return (
        <div>
            <h1 className="pt-6 pb-3 text-[#3498DA] font-bold">Event Logs</h1>
            {logs.map((log, index) => {
                let decodedEvent = { name: "", from: "", to: "", value: "" };
                if (
                    log.topics[0] ===
                    "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
                ) {
                    decodedEvent = {
                        name: "Transfer(index_topic_1 address from, index_topic_2 address to, uint256 value)", // Assuming it's a Transfer event.
                        from: normalizeAddress(log.topics[1]),
                        to: normalizeAddress(log.topics[2]),
                        value: (parseFloat(ethers.BigNumber.from(log.data).toString()) / 10 ** decimals).toString()
                    };
                } else if (
                    log.topics[0] ===
                    "0xb0734c6ca9ae9b7406dd80224cb0488aed0928eef358da7449505fa59b8d7a2a"
                ) {
                    // } else {
                    {
                        const decodedString = ethers.utils.toUtf8String(log.data);
                        // Remove null characters (\x00) and \x1A globally
                        const cleanedString = decodedString.replace(/[\x00\x1A]/g, "");
                        decodedEvent = {
                            ...decodedEvent,
                            name: "Memo(string content)", // Assuming it's a Transfer event.
                            value: cleanedString, // Decode the value from data
                        };
                    }
                }

                return (
                    <div
                        key={index}
                        className="bg-white my-4 px-8 py-4 border shadow-md rounded-lg"
                    >
                        <h2 className="pb-3 font-bold">Log {index + 1}</h2>

                        {/* Flexbox container for each row */}
                        <div className="flex py-6 border-b">
                            <div className="w-1/2 flex items-center">
                                <AiOutlineQuestionCircle />
                                <p className="ml-2">Event Name</p>
                            </div>
                            <div className="w-full">
                                <p>{decodedEvent.name}</p>
                            </div>
                        </div>

                        <div className="flex py-6 border-b">
                            <div className="w-1/2 flex items-center">
                                <AiOutlineQuestionCircle />
                                <p className="ml-2">Topics</p>
                            </div>
                            <div className="w-full">
                                <div className="flex flex-col space-y-2">
                                    <div className="flex items-center">
                                        <span className="bg-gray-100 text-gray-500 text-sm py-1 px-2 rounded">
                                            0
                                        </span>
                                        <span className="ml-2">{log.topics[0]}</span>
                                    </div>

                                    {log.topics.length === 3 && (
                                        <>
                                            <div className="flex items-center">
                                                <span className="bg-gray-100 text-gray-500 text-sm py-1 px-2 rounded">
                                                    1: from
                                                </span>
                                                <span className="ml-2 text-[#357BAD]">
                                                  <Link to={`/address/${decodedEvent.from}`}>
                                                      {decodedEvent.from}
                                                  </Link>
                                                </span>
                                            </div>
                                            <div className="flex items-center">
                                                <span className="bg-gray-100 text-gray-500 text-sm py-1 px-2 rounded">
                                                    2: to
                                                </span>
                                                <span className="ml-2 text-[#357BAD]">
                                                  <Link to={`/address/${decodedEvent.to}`}>
                                                    {decodedEvent.to}
                                                  </Link>
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex py-6">
                            <div className="w-1/2 flex items-center">
                                <AiOutlineQuestionCircle />
                                <p className="ml-2">Data (Value)</p>
                            </div>
                            <div className="w-full">
                                <p>{decodedEvent.value}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Logs;
