import { ethers } from "ethers";

export function normalizeAddress(address: string): string {
    // アドレスを「0x」以降の40文字に切り取る
    if (address.startsWith("0x") && address.length > 42) {
        // パディングを削除し、アドレスの最後の 40 文字を取得
        const trimmedAddress = "0x" + address.slice(-40);
        return ethers.utils.getAddress(trimmedAddress);
    }
    return ethers.utils.getAddress(address);
}
