import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getHistoryByAddress } from "../utils/quorum";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { normalizeAddress } from "../utils/common";

const LIMIT_PER_LOAD = 10;

function AddressDetails() {
    const { address } = useParams<{ address: string }>();
    const [loading, setLoading] = useState(true);
    const [externalTxs, setExternalTxs] = useState<any[]>([]);
    const [nextKey, setNextKey] = useState<string | null>(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        (async () => {
            setExternalTxs([]);
            setLoading(true);
            const transfers = await getHistoryByAddress(address, LIMIT_PER_LOAD);
            // console.log(transfers);
            setExternalTxs(transfers);
            setNextKey(transfers.length > 0 ? transfers[transfers.length - 1].sortkey : null);
            setHasMore(transfers.length === LIMIT_PER_LOAD);
            setLoading(false);
        })();
    }, [address]);

    const loadMoreTransactions = async () => {
        if (nextKey) {
            setLoadingMore(true);
            const moreTransfers = await getHistoryByAddress(address, LIMIT_PER_LOAD, nextKey);
            setExternalTxs([...externalTxs, ...moreTransfers]);
            setNextKey(moreTransfers.length > 0 ? moreTransfers[moreTransfers.length - 1].sortkey : null);
            setHasMore(moreTransfers.length === LIMIT_PER_LOAD);
            setLoadingMore(false);
        }
    };

    return loading ? (
        <h1 className="text-center">Loading...</h1>
    ) : (
        <div>
            <div className="bg-white mx-24 px-8 py-4 my-8 border rounded-lg divide-y">
                <div className="flex">
                  <div className="w-1/2 divide-y">
                      <div className="flex items-center">
                        <AiOutlineQuestionCircle />
                        <p className="ml-2 py-6">Wallet Address</p>
                      </div>
                  </div>
                  <div className="divide-y w-full">
                    <p className="py-6">{address}</p>
                  </div>
                </div>
                <div className="flex py-1 bg-sky-50">
                    <h1 className="w-2/12">Txn Hash</h1>
                    <h1 className="w-1/12">Block</h1>
                    <h1 className="w-2/12">From</h1>
                    <h1 className="w-1/12">Op</h1>
                    <h1 className="w-2/12">Value</h1>
                    <h1 className="w-1/12">Memo</h1>
                </div>
                {externalTxs.length <= 0 ? (
                    <p className="py-4">No any external transaction found.</p>
                ) : (
                    externalTxs.map((tx, index) => {
                        const fromAddress = normalizeAddress(tx.fromaddress);
                        return (
                            <div key={index} className="flex py-4">
                                <p className="w-2/12 text-[#357BAD]">
                                    <Link to={`/tx/${tx.transaction}`}>
                                        {tx.transaction.slice(0, 16)}...
                                    </Link>
                                </p>
                                <p className="w-1/12 text-[#357BAD]">
                                    <Link to={`/block/${tx.blocknumber}`}>
                                        {Number(tx.blocknumber)}
                                    </Link>
                                </p>
                                {fromAddress === address ?
                                    (<p className="w-2/12">
                                        {fromAddress.slice(0, 16)}...
                                    </p>) :
                                    (<p className="w-2/12 text-[#357BAD]">
                                        <Link to={`/address/${fromAddress}`}>
                                            {fromAddress.slice(0, 16)}...
                                        </Link>
                                    </p>)
                                }
                                <p className="w-1/12">
                                    {tx.operation}
                                </p>
                                <p className="w-2/12">
                                    {tx.volume}
                                </p>
                                <p className="w-1/12">
                                    {tx.memo}
                                </p>
                            </div>
                        );
                    })
                )}
                {externalTxs.length > 0 && hasMore && (
                    <button onClick={loadMoreTransactions} disabled={loadingMore} className="mt-4 p-2 bg-blue-500 text-white rounded">
                        {loadingMore ? 'Loading...' : 'Load More'}
                    </button>
                )}
            </div>
        </div>
    );
}

export default AddressDetails;
